:root { 
  --black: #000000;
  --violet-blue: #3048c1;
  --white: #ffffff;
 
  --font-size-l: 48px;
  --font-size-xs: 16px;
 
  --font-family-open_sans: "Open Sans", Helvetica;
}
.opensans-semi-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 600;
}
