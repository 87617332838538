.img-here {
  background-color: var(--violet-blue);
  border: 1px none;
  border-radius: 299px;
  height: 500;
  width: 500;
  position: relative;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;

}
