body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.kiosk {
  background: linear-gradient(180deg, rgb(68, 44, 47) 0%, rgb(234, 0, 0) 75%);
}

.flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-top: 0;
  min-height: 0;
  width: 100%;
  height: 100%;
}
.title {
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 700;
  letter-spacing: 1.44px;
  line-height: 46.5px;
  margin-left: 6px;
  min-height: 47px;
  white-space: nowrap;
}
